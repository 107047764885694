import { useEffect, useState } from 'react'
import * as Txt from '../../styles/text'
import * as S from './styles'
import { useNavigate } from 'react-router-dom'

const AdminMenu = () => {
  const navigate = useNavigate()

  document.addEventListener('hidden.bs.offcanvas', (event) => {
    const scrollY = window.scrollY
    setTimeout(() => window.scrollTo(0, scrollY), 0)
  })

  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [])

  return (
    <>
      <S.OffCanvasWhole
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasAdmin"
        aria-labelledby="offcanvasAdminLabel"
        data-bs-scroll="true"
      >
        <S.OffCanvasHeader className="offcanvas-header">
          <Txt.TextBig className="offcanvas-title" id="offcanvasAdminLabel">
            Menu
          </Txt.TextBig>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </S.OffCanvasHeader>
        <S.OffCanvasBody className="offcanvas-body">
          <S.LinksList>
            <li>
              <a href="/admin/dashboard">
                <Txt.TextBig>Área de admin</Txt.TextBig>
              </a>
            </li>
            <li>
              <a href="/dev#">
                <Txt.TextBig>Voltar para o site</Txt.TextBig>
              </a>
            </li>
            <li>
              <a href="/admin">
                <Txt.TextBig>Sair / desconectar</Txt.TextBig>
              </a>
            </li>
          </S.LinksList>
        </S.OffCanvasBody>
      </S.OffCanvasWhole>
    </>
  )
}

export default AdminMenu
