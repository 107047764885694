import AdminMenu from '../../components/AdminMenu'
import AdminTopMenu from '../../components/AdminTopMenu'

import * as S from './styles'
import AdminAvisosEdit from '../../containers/AdminAvisosEdit'
import AdminAvisosList from '../../components/AdminAvisosList'
import AdminAvisosModal from '../../components/AdminAvisosModal'
import AdminAvisosForm from '../../components/AdminAvisosForm'

const AdminAvisos = () => {
  return (
    <>
      <S.bgAdmin>
        <AdminTopMenu title="Avisos" />
        <AdminMenu />
        {/* <AdminAvisosEdit /> */}
        <AdminAvisosList />
        <AdminAvisosForm />
        <AdminAvisosModal />
      </S.bgAdmin>
    </>
  )
}

export default AdminAvisos
