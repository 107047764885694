import AdminMenu from '../../components/AdminMenu'
import AdminTopMenu from '../../components/AdminTopMenu'
import AdminTurmaCard from '../../components/AdminTurmaCard'

import * as S from './styles'

const AdminTurmas = () => {
  return (
    <>
      <S.bgAdmin>
        <AdminTopMenu title="Turmas" />
        <AdminMenu />
        <div className="input-group mt-3 mb-3">
          <span className="input-group-text">Pesquisar</span>
          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
            />
            <label htmlFor="floatingInput">Nome da turma</label>
          </div>
        </div>
        <AdminTurmaCard />
      </S.bgAdmin>
    </>
  )
}

export default AdminTurmas
