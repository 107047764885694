import ModalVaga from '../ModalVaga'

interface cardProps {
  vaga: string
}

const CardVaga = ({ vaga }: cardProps) => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <h5 className="card-title">{vaga}</h5>
        <p className="card-text"></p>
        <button
          className="btn btn-warning"
          data-bs-toggle="modal"
          data-bs-target={`#staticBackdrop-${vaga}`}
        >
          Ver detalhes da vaga
        </button>
        <ModalVaga nome_vaga={vaga} />
      </div>
    </div>
  )
}

export default CardVaga
