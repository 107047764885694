import styled from 'styled-components'
import { cores } from '../../styles'

export const TopMenuBg = styled.div`
  background-color: ${cores.corPrincipal};
  color: #fff; // Mudar depois
  height: 70px;
  border-radius: 8px;
`

export const navBar = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 0 0 0 8px;
  }
`
