import { useState } from 'react'
import * as S from './styles'

interface formProps {
  nome_vaga: string
}

const FormVaga = ({ nome_vaga }: formProps) => {
  const [vaga, setVaga] = useState(nome_vaga)
  const [nome, setNome] = useState('')
  const [idade, setIdade] = useState('')
  const [sexo, setSexo] = useState('')
  const [endereco, setEndereco] = useState('')
  const [tel, setTel] = useState('')
  const [email, setEmail] = useState('')
  const [anexo, setAnexo] = useState()

  return (
    <>
      <S.selectInput className="form-floating mb-3 big">
        <select className="form-select" aria-label="select_sexo" disabled>
          <option selected>{`Vaga desejada: ${nome_vaga}`}</option>
        </select>
      </S.selectInput>
      <div className="form-floating mb-3">
        <input
          type="text"
          className="form-control"
          id="nomeInput"
          placeholder="Nome"
          onChange={(e) => setNome(e.target.value)}
        />
        <label htmlFor="nomeInput">Nome Completo</label>
      </div>
      <S.doubleInputContainer>
        <S.idadeInput className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="idadeInput"
            placeholder="Idade"
            onChange={(e) => setIdade(e.target.value)}
          />
          <label htmlFor="idadeInput">Idade</label>
        </S.idadeInput>
        <S.selectInput className="form-floating mb-3">
          <select
            className="form-select"
            aria-label="select_sexo"
            onChange={(e) => setSexo(e.target.value)}
          >
            <option selected disabled>
              Sexo:
            </option>
            <option value="1">Masculino</option>
            <option value="2">Feminino</option>
            <option value="3">Não especificar</option>
          </select>
        </S.selectInput>
      </S.doubleInputContainer>
      <div className="form-floating mb-3">
        <input
          type="text"
          className="form-control"
          id="enderecoInput"
          placeholder="Endereço"
          onChange={(e) => setEndereco(e.target.value)}
        />
        <label htmlFor="enderecoInput">Endereço completo</label>
      </div>
      <div className="form-floating mb-3">
        <input
          type="tel"
          className="form-control"
          id="telInput"
          placeholder="Telefone"
          onChange={(e) => setTel(e.target.value)}
        />
        <label htmlFor="telInput">Telefone</label>
      </div>
      <div className="form-floating mb-3">
        <input
          type="email"
          className="form-control"
          id="emailInput"
          placeholder="E-mail"
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="emailInput">E-mail</label>
      </div>
      <div>
        <label htmlFor="formFileLg" className="form-label">
          Por favor, anexe seu currículo abaixo:
        </label>
        <input
          className="form-control form-control-lg"
          id="formFileLg"
          type="file"
        />
      </div>
    </>
  )
}

export default FormVaga
