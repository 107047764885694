import styled from 'styled-components'
import { cores } from '../../styles'

export const LoginCard = styled.div`
  background-color: ${cores.corPrincipal};
  border-radius: 16px;
  padding: 24px;
  margin-top: 80px;
  filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.3));
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const Logo = styled.img`
  height: 200px;
  margin: 24px 0;
`

export const TextArea = styled.div`
  color: ${cores.corSecundaria};
  text-align: center;
`

export const LoginBtn = styled.button`
  background-color: ${cores.corSecundaria};
  color: ${cores.corPrincipal};
  width: 100%;
  border: none;
  font-weight: bold;
  transition: 0.2s ease;

  &:hover {
    background-color: ${cores.corPrincipalDown};
    color: ${cores.corSecundaria};
    transition: 0.2s ease;
  }
`
