import { useState, useEffect } from 'react'

import * as S from './styles'
import * as Txt from '../../styles/text'
import { BsFillTrash3Fill } from 'react-icons/bs'

interface Avisos {
  id: string
  titulo: string
  corpo: string
  created_at: string
  updated_at: string
}

const AdminAvisosList = () => {
  const [avisosError, setAvisosError] = useState<string | null>(null)
  const [avisos, setAvisos] = useState<Avisos[]>([])
  const [error, setError] = useState<string | null>(null)
  const [editingAvisoId, setEditingAvisoId] = useState<string | null>(null)
  const [formValues, setFormValues] = useState({
    titulo: '',
    corpo: ''
  })

  const fetchAvisos = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/avisos/')
      if (!response.ok) {
        throw new Error(
          'Não foi possível requisitar os dados de localhost:8000/api/avisos/'
        )
      }
      const data = await response.json()
      setAvisos(data)
    } catch (err: any) {
      setAvisosError(err.message)
    }
  }

  const deleteAviso = async (id_aviso: string) => {
    try {
      const response = await fetch(
        `http://localhost:8000/api/avisos/${id_aviso}/`,
        {
          method: 'DELETE'
        }
      )
      if (!response.ok) {
        throw new Error(
          `Erro ao deletar o aviso da API em localhost:8000/api/avisos/${id_aviso}`
        )
      }
      window.location.reload()
    } catch (err: any) {
      setError(err.message)
    }
  }

  const handleEdit = (aviso: Avisos) => {
    setEditingAvisoId(aviso.id)
    setFormValues({ titulo: aviso.titulo, corpo: aviso.corpo })
  }

  const handleCancelEdit = () => {
    setEditingAvisoId(null)
    setFormValues({ titulo: '', corpo: '' })
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormValues((prev) => ({ ...prev, [name]: value }))
  }

  const handleSave = async (id: string) => {
    try {
      const response = await fetch(`http://localhost:8000/api/avisos/${id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formValues)
      })

      if (!response.ok) {
        throw new Error('Falha ao salvar as alterações.')
      }

      const updatedAviso = await response.json()

      setAvisos((prevAvisos) =>
        prevAvisos.map((aviso) => (aviso.id === id ? updatedAviso : aviso))
      )

      setEditingAvisoId(null)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchAvisos()
  }, [])

  return (
    <>
      {avisos.map((aviso) => (
        <S.CardAviso className="mb-3" key={aviso.id}>
          <div>
            <S.BodyAviso>
              {editingAvisoId === aviso.id ? (
                <form>
                  <input
                    type="text"
                    name="titulo"
                    value={formValues.titulo}
                    onChange={handleInputChange}
                    className="form-control mb-3"
                    placeholder="Título do aviso"
                  />
                  <textarea
                    name="corpo"
                    value={formValues.corpo}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Corpo do aviso"
                  />
                  <button
                    type="button"
                    className="btn btn-secondary w-100 mt-2"
                    onClick={handleCancelEdit}
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="btn btn-success w-100 mt-2"
                    onClick={() => handleSave(aviso.id)}
                  >
                    Salvar alterações
                  </button>
                </form>
              ) : (
                <div>
                  <div>
                    <S.DeleteBtn
                      type="button"
                      onClick={() => deleteAviso(aviso.id)}
                    >
                      <BsFillTrash3Fill />
                    </S.DeleteBtn>
                  </div>
                  <Txt.TitleMd>{aviso.titulo}</Txt.TitleMd>
                  <Txt.TextMd className="mb-3">{aviso.corpo}</Txt.TextMd>
                  <Txt.TextSm>{`Modificado em: ${aviso.updated_at}`}</Txt.TextSm>
                  <button
                    type="button"
                    className="btn btn-warning w-100 mt-3"
                    onClick={() => handleEdit(aviso)}
                  >
                    Editar aviso
                  </button>
                </div>
              )}
            </S.BodyAviso>
          </div>
        </S.CardAviso>
      ))}
      {avisosError && <p className="text-danger">{avisosError}</p>}
    </>
  )
}

export default AdminAvisosList
