import styled from 'styled-components'
import { cores } from '../../styles'

export const FormContainer = styled.form`
  padding: 16px;
  background-color: ${cores.corPrincipal};
  border-radius: 12px;
  color: #fff;

  h2 {
    margin-bottom: 24px;
  }
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  input {
    width: 100%;
  }
`

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`
