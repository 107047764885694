import * as Txt from '../../styles/text'
import * as S from './styles'

const AdminTurmaCard = () => {
  return (
    <>
      <S.FormTurmaCard>
        <Txt.TitleMd className="text-center">
          {'Creche - Berçário (1 ano)'}
        </Txt.TitleMd>
        <form action="">
          <div className="mb-3">
            <S.LabelContainer>
              <Txt.TitleMd>Objetivo principal</Txt.TitleMd>
            </S.LabelContainer>
            <Txt.TextMd className="text-center">
              Promoção de segurança emocional, socialização inicial e estímulos
              sensoriais básicos.
            </Txt.TextMd>
          </div>
          <div className="mb-3">
            <S.LabelContainer>
              <Txt.TitleMd>Atividades típicas</Txt.TitleMd>
            </S.LabelContainer>
            <S.AtividadesCard>
              <Txt.TitleMd>Estímulo ao desenvolvimento motor</Txt.TitleMd>
              <Txt.TextMd>
                Engatinhar, dar os primeiros passos com apoio, manipular objetos
                grandes.
              </Txt.TextMd>
            </S.AtividadesCard>
            <button type="button" className="btn btn-secondary w-100">
              Adicionar Atividade
            </button>
          </div>
        </form>
      </S.FormTurmaCard>
    </>
  )
}

export default AdminTurmaCard
