import AdminMenu from '../../components/AdminMenu'
import AdminTopMenu from '../../components/AdminTopMenu'
import AdminSobreForms from '../../containers/AdminSobreForms'

import * as S from './styles'

const AdminSobre = () => {
  return (
    <>
      <S.bgAdmin>
        <AdminTopMenu title="Sobre" />
        <AdminMenu />
        <AdminSobreForms />
      </S.bgAdmin>
    </>
  )
}

export default AdminSobre
