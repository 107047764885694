import styled from 'styled-components'
import { cores } from '../../styles'

export const AdminCardContainer = styled.div`
  background-color: ${cores.corPrincipal};
  border: 4px solid ${cores.shadePrincipal};
  border-radius: 12px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.2s ease;
  color: #fff;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${cores.corSecundaria};
    transition: 0.2s ease;
  }
`

export const TextArea = styled.div`
  margin-left: 24px;

  h2 {
    margin: 0;
  }
`

export const ImgArea = styled.div`
  height: 120px;
  overflow: hidden;

  img {
    height: 150px;
    transform: translate(0px, -20px);
  }
`
