import styled from 'styled-components'

export const AvisoForm = styled.form`
  .hidden {
    display: none;
  }
`

export const AvisoTextInput = styled.input`
  color: #000;
`

export const AvisoTextarea = styled.textarea`
  height: 180px;
`

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`
