import { useNavigate } from 'react-router-dom'
import * as Global from '../../styles/index'
import * as Txt from '../../styles/text'
import * as S from './styles'

const AdminLoginCard = () => {
  const navigate = useNavigate()

  return (
    <>
      <Global.Container>
        <S.LoginCard>
          <S.LogoContainer>
            <S.Logo
              src="https://static-file-vault.vercel.app/cdtchechela/logo_cdtcc.svg"
              alt="Logo da escola"
            />
          </S.LogoContainer>
          <S.TextArea>
            <Txt.TextSm>Acesso restrito à administração do site.</Txt.TextSm>
            <Txt.TextSm>
              Por favor, insira suas credenciais para continuar.
            </Txt.TextSm>
          </S.TextArea>
          <div className="form-floating mb-3">
            <input
              type="text"
              name="user"
              id="userInput"
              className="form-control"
              placeholder=""
            />
            <label htmlFor="user">Seu Login</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="password"
              name="user"
              id="userInput"
              className="form-control"
              placeholder=""
            />
            <label htmlFor="user">Sua senha</label>
          </div>
          <S.LoginBtn
            type="button"
            className="btn"
            onClick={() => navigate('/admin/dashboard')}
          >
            Entrar
          </S.LoginBtn>
        </S.LoginCard>
      </Global.Container>
    </>
  )
}

export default AdminLoginCard
