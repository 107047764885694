import AdminTopMenu from '../../components/AdminTopMenu'
import AdminMenu from '../../components/AdminMenu'
import AdminTrabalheCard from '../../components/AdminTrabalheCard'

import * as S from './styles'

const AdminTrabalhe = () => {
  return (
    <>
      <S.bgAdmin>
        <AdminTopMenu title="Trabalhe conosco" />
        <AdminMenu />
        <AdminTrabalheCard
          nome_vaga="Nome da vaga"
          desc_vaga="Um texto breve descrevendo o que deverá ser feito ao ocupar a vaga."
          req_vaga="Todos os requisitos desejados e/ou necessários para ocupar a vaga."
        />
      </S.bgAdmin>
    </>
  )
}

export default AdminTrabalhe
