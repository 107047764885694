import * as S from './styles'
import * as Txt from '../../styles/text'

interface TrabalheProps {
  nome_vaga: string
  desc_vaga: string
  req_vaga: string
}

const AdminTrabalheCard = ({
  nome_vaga,
  desc_vaga,
  req_vaga
}: TrabalheProps) => {
  return (
    <>
      <S.AdminVagaCard>
        <Txt.TitleBig className="text-center">{nome_vaga}</Txt.TitleBig>
        <Txt.TitleMd className="text-center">Descrição</Txt.TitleMd>
        <Txt.TextMd className="text-center">{desc_vaga}</Txt.TextMd>
        <Txt.TitleMd className="text-center">Requisitos</Txt.TitleMd>
        <Txt.TextMd className="text-center">{req_vaga}</Txt.TextMd>
        <div>
          <S.EditBtn type="button" className="btn btn-warning w-100">
            Editar
          </S.EditBtn>
        </div>
      </S.AdminVagaCard>
    </>
  )
}

export default AdminTrabalheCard
