import styled from 'styled-components'
import { cores } from '../../styles'

export const ConstruindoContainer = styled.div`
  padding: 10vw;
  text-align: center;
  background-color: ${cores.corPrincipal};
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;

  @media (min-width: 768px) {
    display: block;
    padding: 10vh;
    height: 100vh;
  }

  @media (min-width: 1024px) {
    display: block;
    padding: 2vw;
    height: 100vh;
  }
`

export const Logo = styled.img`
  max-width: 60%;
  margin: 30px auto 20px;
  animation: glow 8s ease-in-out infinite;

  @media (min-width: 768px) {
    max-width: 30%;
    margin: 20px auto 20px;
  }

  @media (min-width: 1024px) {
    max-width: 20%;
  }

  @keyframes glow {
    0% {
      filter: drop-shadow(0px 0px 5px rgba(222, 162, 16, 0.1));
    }
    50% {
      filter: drop-shadow(0px 0px 18px rgba(222, 162, 16, 0.4));
    }
    100% {
      filter: drop-shadow(0px 0px 5px rgba(222, 162, 16, 0.1));
    }
  }
`
