import AdminMenu from '../../components/AdminMenu'
import Dashboard from '../../containers/Dashboard'
import * as S from './styles'

const AdminArea = () => {
  return (
    <>
      <AdminMenu />
      <S.bgAdmin>
        <S.LastCard>
          <Dashboard />
        </S.LastCard>
      </S.bgAdmin>
    </>
  )
}

export default AdminArea
