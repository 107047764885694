import FormButtons from '../../components/FormButtons'

import * as S from './styles'

const AdminSobreForms = () => {
  return (
    <>
      <form action="">
        <div>
          <h1 className="text-center text-white mt-3 mb-3">Sobre a escola</h1>
        </div>
        <S.SobreTextarea
          className="form-control"
          value="Texto sobre a escola atual na homepage"
          disabled
        />
        <FormButtons leftButton="Editar" rightButton="Salvar" />
      </form>
      <form action="">
        <div>
          <h1 className="text-center text-white mt-3 mb-3">Missão</h1>
        </div>
        <S.SobreTextarea
          className="form-control"
          placeholder="Texto sobre a missão da escola, exibido na sessão Sobre nós, da página inicial."
        />
        <FormButtons leftButton="Editar" rightButton="Salvar" />
      </form>
      <form action="">
        <div>
          <h1 className="text-center text-white mt-3 mb-3">Visão</h1>
        </div>
        <S.SobreTextarea
          className="form-control"
          placeholder="Texto sobre a visão da escola, exibido na sessão Sobre nós, da página inicial."
        />
        <FormButtons leftButton="Editar" rightButton="Salvar" />
      </form>
      <form action="">
        <div>
          <h1 className="text-center text-white mt-3 mb-3">Valores</h1>
        </div>
        <S.SobreTextarea
          className="form-control"
          placeholder="Texto sobre os valores da escola, exibido na sessão Sobre nós, da página inicial."
        />
        <FormButtons leftButton="Editar" rightButton="Salvar" />
      </form>
    </>
  )
}

export default AdminSobreForms
