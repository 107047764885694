import styled from 'styled-components'

export const doubleInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const idadeInput = styled.div`
  max-width: 25%;
`

export const selectInput = styled.div`
  max-width: 100%;
`
