import AdminMenu from '../../components/AdminMenu'
import AdminTopMenu from '../../components/AdminTopMenu'
import ImgGallery from '../../components/ImgGallery'
import ImgUploadForm from '../../components/ImgUploadForm'
import * as BG from '../AdminArea/styles'

const AdminCarousel = () => {
  return (
    <>
      <BG.bgAdmin>
        <AdminTopMenu title="Carrossel de imagens" />
        <AdminMenu />
        <ImgGallery />
        <ImgUploadForm />
      </BG.bgAdmin>
    </>
  )
}

export default AdminCarousel
