import { useState, useEffect } from 'react'
import { format } from 'date-fns'

import { BsFillTrash3Fill } from 'react-icons/bs'

import * as S from './styles'
import * as Txt from '../../styles/text'

interface ImageData {
  id: string
  image: string
  created_at: string
}

const ImgGallery = () => {
  const [error, setError] = useState<string | null>(null)
  const [imagens, setImagens] = useState<ImageData[]>([])

  const fetchImages = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/carousel/')
      if (!response.ok) {
        throw new Error(
          'Erro ao requisitar as imagens da API em localhost:8000/api/carousel'
        )
      }
      const data = await response.json()
      setImagens(data)
      console.log(imagens)
    } catch (err: any) {
      setError(err.message)
    }
  }

  const deleteImage = async (id_imagem: string) => {
    try {
      const response = await fetch(
        `http://localhost:8000/api/carousel/${id_imagem}/`,
        {
          method: 'DELETE'
        }
      )
      if (!response.ok) {
        throw new Error(
          `Erro ao deletar a imagem da API em localhost:8000/api/carousel/${id_imagem}`
        )
      }

      setImagens(imagens.filter((imagem) => imagem.id !== id_imagem))
      await fetchImages()
    } catch (err: any) {
      setError(err.message)
    }
  }

  useEffect(() => {
    fetchImages()
  }, [])

  return (
    <>
      <div>
        <Txt.TextMd className="text-center text-white mt-3">
          As imagens exibidas abaixo, são as imagens que aparecem na página
          inicial do site.
        </Txt.TextMd>
      </div>
      <S.GalleryContainer>
        {imagens.length > 0 ? (
          imagens.map((imagem) => {
            const formatDate = format(new Date(imagem.created_at), 'dd/MM/yyyy')
            const formatTime = format(new Date(imagem.created_at), 'HH:mm')

            return (
              <S.ImgBox key={imagem.id}>
                <div>
                  <S.DeleteImageBtn
                    type="button"
                    onClick={() => deleteImage(imagem.id)}
                  >
                    <BsFillTrash3Fill />
                  </S.DeleteImageBtn>
                </div>
                <img
                  src={imagem.image}
                  alt={`Imagem carregada em: ${formatDate} às ${formatTime}`}
                  title={`Imagem carregada em: ${formatDate} às ${formatTime}`}
                />
              </S.ImgBox>
            )
          })
        ) : (
          <p className="text-white text-center">Imagens não encontradas</p>
        )}
      </S.GalleryContainer>
    </>
  )
}

export default ImgGallery
