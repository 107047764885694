import { useEffect, useState } from 'react'
import { BsSearch } from 'react-icons/bs'

import * as S from './styles'
import * as Global from '../../styles/index'
import * as Txt from '../../styles/text'

interface Avisos {
  id: string
  titulo: string
  corpo: string
  created_at: string
  updated_at: string
}

const Avisos = () => {
  const [isHidden, setIsHidden] = useState(true)
  const [avisosError, setAvisosError] = useState<string | null>(null)
  const [avisos, setAvisos] = useState<Avisos[]>([])

  const fetchAvisos = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/avisos/')
      if (!response.ok) {
        throw new Error(
          'Não foi possível requisitar os dados de localhost:8000/api/avisos/'
        )
      }
      const data = await response.json()
      setAvisos(data)
    } catch (err: any) {
      setAvisosError(err.message)
    }
  }

  useEffect(() => {
    fetchAvisos()
  }, [])

  return (
    <>
      <S.QuadroAvisos id="avisos">
        <h1>Quadro de avisos</h1>
        <Global.Container>
          {avisos.map((aviso) => (
            <>
              <S.CardAviso
                data-bs-toggle="modal"
                data-bs-target={`#staticBackdrop-${aviso.id}`}
                key={aviso.id}
              >
                <S.BodyAviso>
                  <Txt.TitleMd>{aviso.titulo}</Txt.TitleMd>
                  <p>Clique para ler</p>
                </S.BodyAviso>
                <S.IconAviso>
                  <BsSearch className="fs-5" />
                </S.IconAviso>
              </S.CardAviso>

              <S.ModalContainer
                className="modal fade"
                id={`staticBackdrop-${aviso.id}`}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby={`staticBackdropLabel-${aviso.id}`}
                aria-hidden="true"
                key={aviso.id}
              >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1
                        className="modal-title fs-3"
                        id={`staticBackdropLabel-${aviso.id}`}
                      >
                        {aviso.titulo}
                      </h1>
                    </div>
                    <div className="modal-body">
                      <div>
                        <Txt.TextMd>{aviso.corpo}</Txt.TextMd>
                        <br />
                        <Txt.TextSm>
                          Atualizado por último em: {aviso.updated_at}
                        </Txt.TextSm>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-dismiss="modal"
                      >
                        Fechar
                      </button>
                    </div>
                  </div>
                </div>
              </S.ModalContainer>
            </>
          ))}
        </Global.Container>
      </S.QuadroAvisos>
    </>
  )
}

export default Avisos
