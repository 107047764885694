import { useState } from 'react'

import * as Txt from '../../styles/text'
import * as S from './styles'

const ImgUploadForm = (data: object) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [uploadError, setUploadError] = useState<string | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imagem = event.target.files?.[0]
    if (imagem) {
      setSelectedFile(imagem)
    }
  }

  const adicionarImagem = async () => {
    if (!selectedFile!) {
      alert('Por favor, escolha uma imagem antes de enviar.')
      return
    }

    const formData = new FormData()
    formData.append('image', selectedFile)

    try {
      const response = await fetch('http://localhost:8000/api/carousel/', {
        method: 'POST',
        body: formData
      })
      if (!response.ok) {
        throw new Error(
          'Erro ao fazer o upload da imagem em localhost:8000/api/carousel/'
        )
      }
    } catch (err: any) {
      setUploadError(err.message)
    }
  }

  return (
    <>
      <S.FormContainer action="">
        <Txt.TitleMd className="text-center">Adicionar imagem</Txt.TitleMd>
        <S.InputContainer>
          <input
            type="file"
            name="img_input"
            id=""
            className="form-control"
            onChange={handleFileChange}
          />
        </S.InputContainer>
        <S.BtnContainer>
          <div>
            <button
              type="submit"
              className="btn btn-success"
              onClick={adicionarImagem}
            >
              Enviar
            </button>
          </div>
        </S.BtnContainer>
      </S.FormContainer>
    </>
  )
}

export default ImgUploadForm
