import styled from 'styled-components'
import { cores } from '../../styles'

export const FormContatoCard = styled.div`
  background-color: #fff;
  margin-top: 20px;
  border-radius: 8px;
  padding: 24px;

  h2 {
    margin: 16px 0 24px;
  }
`
