import styled from 'styled-components'
import { cores } from '../../styles'

export const bgAdmin = styled.div`
  background-color: ${cores.corPrincipalDown};
  height: 100%;
  padding: 24px;
`

export const LastCard = styled.div`
  margin-bottom: 60px;
`
