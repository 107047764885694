import styled from 'styled-components'
import { cores } from '../../styles'

export const bgAdmin = styled.div`
  background-color: ${cores.corPrincipalDown};
  height: 100vh;
  padding: 24px;

  p {
    margin-bottom: 0;
  }
`
