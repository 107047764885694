import styled from 'styled-components'

export const AdminVagaCard = styled.div`
  background-color: #fff;
  margin-top: 20px;
  border-radius: 8px;
  padding: 24px;

  h2 {
    margin-bottom: 20px;
  }
`

export const EditBtn = styled.button`
  margin-top: 16px;
`
