import styled from 'styled-components'

export const GalleryContainer = styled.div`
  margin: 24px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 16px;
`

export const ImgBox = styled.div`
  height: 200px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.4));
  position: relative;

  img {
    height: 200px;
  }
`

export const DeleteImageBtn = styled.button`
  background-color: rgba(255, 0, 0, 0.6);
  color: #fff;
  padding: 4px 8px;
  border-radius: 50%;
  border: none;
  position: absolute;
  top: 8px;
  right: 8px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));
  transition: ease 0.3s;

  &:hover {
    background-color: rgba(255, 0, 0, 0.8);
    transition: ease 0.3s;
  }
`
