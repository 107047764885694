import styled from 'styled-components'
import { cores } from '../../styles'

export const FormTurmaCard = styled.div`
  background-color: #fff;
  margin-top: 20px;
  border-radius: 8px;
  padding: 24px;

  h2 {
    margin: 16px 0 24px;
  }
`

export const TurmaTextarea = styled.textarea`
  height: 150px;
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 8px;
`

export const AtividadesCard = styled.div`
  background-color: ${cores.corPrincipal};
  color: #fff;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;

  h2 {
    margin: 0px 0px 8px;
    color: ${cores.corSecundaria};
  }

  p {
    margin: 0px;
  }
`
