import styled from 'styled-components'
import { cores } from '../../styles'

export const ModalSuccess = styled.div`
  background-color: ${cores.corPrincipal};
  color: #fff;
`

export const TextContainer = styled.div`
  margin: 24px 0 36px;

  h2 {
    margin-bottom: 36px;
  }
`
