import styled from 'styled-components'
import { cores } from '../../styles'

export const CardAviso = styled.div`
  color: ${cores.corPrincipal};
  background-color: #fff;
  margin: 8px 0;
  border-radius: 12px;
  display: block;
  filter: drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.1));
  transition: ease 0.3s;

  .imagem {
    background-color: #fff;
  }

  .hidden {
    display: none;
  }
`

export const BodyAviso = styled.div`
  padding: 14px;
`

export const DeleteBtn = styled.button`
  background-color: rgba(255, 0, 0, 0.6);
  color: #fff;
  padding: 4px 8px;
  border-radius: 50%;
  border: none;
  position: absolute;
  top: 8px;
  right: 8px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));
  transition: ease 0.3s;

  &:hover {
    background-color: rgba(255, 0, 0, 0.8);
    transition: ease 0.3s;
  }
`
