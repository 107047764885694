import AdminTopMenu from '../../components/AdminTopMenu'
import AdminMenu from '../../components/AdminMenu'
import AdminContatoCard from '../../components/AdminContatoCard'

import * as S from './styles'

const AdminContato = () => {
  return (
    <>
      <S.bgAdmin>
        <AdminTopMenu title="Contato" />
        <AdminMenu />
        <AdminContatoCard />
      </S.bgAdmin>
    </>
  )
}

export default AdminContato
