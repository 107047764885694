import AdminLoginCard from '../../containers/AdminLoginCard'
import * as S from './styles'

const AdminLogin = () => {
  return (
    <>
      <S.bgAdmin>
        <AdminLoginCard />
      </S.bgAdmin>
    </>
  )
}

export default AdminLogin
