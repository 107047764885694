import { useState } from 'react'
import * as S from './styles'

const AdminAvisosForm = () => {
  const [titulo, setTitulo] = useState<string>('')
  const [corpo, setCorpo] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [isCreating, setIsCreating] = useState<boolean>(false)

  const handleSave = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/avisos/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ titulo, corpo })
      })

      if (!response.ok) {
        throw new Error('Falha ao criar o aviso.')
      }

      setTitulo('')
      setCorpo('')
      setError(null)
      window.location.reload()
    } catch (error) {
      setError(
        'Ocorreu um erro ao criar o aviso. Parece que um dos campos está em branco.'
      )
    }
  }

  const handleCancel = () => {
    isCreating ? setIsCreating(false) : setIsCreating(true)
    setTitulo('')
    setCorpo('')
    setError(null)
  }

  return (
    <>
      <S.AvisoForm onSubmit={(e) => e.preventDefault()} className="mt-4">
        <div>
          <button
            type="button"
            className={isCreating ? 'hidden' : 'btn btn-success w-100 mt-2'}
            onClick={() => setIsCreating(true)}
          >
            Adicionar aviso
          </button>
        </div>
        <div className={isCreating ? '' : 'hidden'}>
          <div className="form-floating mb-3">
            <S.AvisoTextInput
              type="text"
              className="form-control"
              id="titleInput"
              placeholder="Título do aviso"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
            />
            <label htmlFor="titleInput">Título do aviso</label>
          </div>
          <div className="mb-3">
            <S.AvisoTextarea
              className="form-control"
              id="bodyInput"
              placeholder="Corpo do aviso"
              value={corpo}
              onChange={(e) => setCorpo(e.target.value)}
            />
          </div>
          {error && <p className="text-danger">{error}</p>}
          <S.BtnContainer>
            <button
              type="button"
              className="btn btn-secondary mb-5 me-2"
              onClick={handleCancel}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-success mb-5"
              onClick={handleSave}
            >
              Criar aviso
            </button>
          </S.BtnContainer>
        </div>
      </S.AvisoForm>
    </>
  )
}

export default AdminAvisosForm
