import { useState, useEffect } from 'react'

import * as S from './styles'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

interface ImageData {
  id: string
  image: string
  created_at: string
}

const Carousel = () => {
  const [error, setError] = useState<string | null>(null)
  const [imagens, setImagens] = useState<ImageData[]>([])

  const fetchImages = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/carousel/')
      if (!response.ok) {
        throw new Error(
          'Erro ao requisitar as imagens da API em localhost:8000/api/carousel'
        )
      }
      const data = await response.json()
      setImagens(data)
      console.log(imagens)
    } catch (err: any) {
      setError(err.message)
    }
  }

  useEffect(() => {
    fetchImages()
  }, [])

  return (
    <>
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          {imagens.map((imagem, index) => {
            return (
              <>
                <S.CarouselImageContainer
                  key={imagem.id}
                  className={`carousel-item ${index === 0 ? 'active' : ''}`}
                >
                  <S.CarouselImage
                    src={imagem.image}
                    className="d-block w-100"
                    alt={`Imagem: ${imagem.id}`}
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>First slide label</h5>
                    <p>
                      Some representative placeholder content for the first
                      slide.
                    </p>
                  </div>
                </S.CarouselImageContainer>
              </>
            )
          })}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  )
}

export default Carousel
