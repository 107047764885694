import * as S from './styles'
import * as Txt from '../../styles/text'
import { useNavigate } from 'react-router-dom'

interface cardProps {
  descr: string
  imgLink: string
  navigateTo: string
}

const AdminCard = ({ descr, imgLink, navigateTo }: cardProps) => {
  const navigate = useNavigate()

  return (
    <>
      <S.AdminCardContainer onClick={() => navigate(`${navigateTo}`)}>
        <S.TextArea>
          <Txt.TitleBig>{descr}</Txt.TitleBig>
        </S.TextArea>
        <S.ImgArea>
          <img src={imgLink} alt="imagem do card" />
        </S.ImgArea>
      </S.AdminCardContainer>
    </>
  )
}

export default AdminCard
