import CardVaga from '../../components/CardVaga'
import * as Global from '../../styles/index'
import * as S from './styles'

const Vagas = () => {
  return (
    <S.VagasContainer>
      <Global.Container>
        <CardVaga vaga="Estagiária" />
        <CardVaga vaga="Professora" />
        <CardVaga vaga="Auxiliar de Transporte" />
      </Global.Container>
    </S.VagasContainer>
  )
}

export default Vagas
