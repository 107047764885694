import * as S from './styles'
import * as Menu from '../../containers/Header/styles'

interface propsMenu {
  title: string
}

const AdminTopMenu = ({ title }: propsMenu) => {
  return (
    <>
      <S.TopMenuBg>
        <S.navBar>
          <h4>{title}</h4>
          <Menu.MenuHamburger
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasAdmin"
            aria-controls="offcanvasAdmin"
          >
            <Menu.MenuHamburgerLine />
            <Menu.MenuHamburgerLine className="center" />
            <Menu.MenuHamburgerLine />
          </Menu.MenuHamburger>
        </S.navBar>
      </S.TopMenuBg>
    </>
  )
}

export default AdminTopMenu
