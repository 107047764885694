import * as S from './styles'

interface buttonProps {
  leftButton: string
  rightButton: string
}

const FormButtons = ({ leftButton, rightButton }: buttonProps) => {
  return (
    <>
      <S.BtnContainer>
        <div>
          <button type="button" className="btn btn-danger me-3">
            {leftButton}
          </button>
          <button type="button" className="btn btn-success">
            {rightButton}
          </button>
        </div>
      </S.BtnContainer>
    </>
  )
}

export default FormButtons
