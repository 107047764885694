import { GlobalStyle } from './styles'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import Home from './pages/Home'
import Construindo from './pages/Construindo'
import TrabalheConosco from './pages/TrabalheConosco'
import AdminArea from './pages/AdminArea'
import AdminLogin from './pages/AdminLogin'
import AdminCarousel from './pages/AdminCarousel'
import AdminAvisos from './pages/AdminAvisos'
import AdminSobre from './pages/AdminSobre'
import AdminTurmas from './pages/AdminTurmas'
import AdminContato from './pages/AdminContato'
import AdminTrabalhe from './pages/AdminTrabalhe'

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Construindo />
  },
  {
    path: '/dev',
    element: <Home />
  },
  {
    path: '/vagasDisponiveis',
    element: <TrabalheConosco />
  },
  {
    path: '/admin',
    element: <AdminLogin />
  },
  {
    path: '/admin/dashboard',
    element: <AdminArea />
  },
  {
    path: '/admin/dashboard/carousel',
    element: <AdminCarousel />
  },
  {
    path: '/admin/dashboard/avisos',
    element: <AdminAvisos />
  },
  {
    path: '/admin/dashboard/sobre',
    element: <AdminSobre />
  },
  {
    path: '/admin/dashboard/turmas',
    element: <AdminTurmas />
  },
  {
    path: '/admin/dashboard/contato',
    element: <AdminContato />
  },
  {
    path: '/admin/dashboard/trabalhe-conosco',
    element: <AdminTrabalhe />
  }
])

function App() {
  return (
    <>
      <GlobalStyle />
      <RouterProvider router={routes} />
    </>
  )
}

export default App
