import AdminCard from '../../components/AdminCard'
import AdminTopMenu from '../../components/AdminTopMenu'

const Dashboard = () => {
  return (
    <>
      <AdminTopMenu title="Bem vindo(a), usuario" />
      <AdminCard
        descr="Fotos do carrossel"
        imgLink="https://static-file-vault.vercel.app/cdtchechela/admin_fotos.svg"
        navigateTo="/admin/dashboard/carousel"
      />
      <AdminCard
        descr="Avisos"
        imgLink="https://static-file-vault.vercel.app/cdtchechela/admin_avisos.svg"
        navigateTo="/admin/dashboard/avisos"
      />
      <AdminCard
        descr="Sobre"
        imgLink="https://static-file-vault.vercel.app/cdtchechela/admin_sobre.svg"
        navigateTo="/admin/dashboard/sobre"
      />
      <AdminCard
        descr="Turmas"
        imgLink="https://static-file-vault.vercel.app/cdtchechela/admin_turmas.svg"
        navigateTo="/admin/dashboard/turmas"
      />
      <AdminCard
        descr="Contato"
        imgLink="https://static-file-vault.vercel.app/cdtchechela/admin_contato.svg"
        navigateTo="/admin/dashboard/contato"
      />
      <AdminCard
        descr="Trabalhe conosco"
        imgLink="https://static-file-vault.vercel.app/cdtchechela/admin_trabalhe.svg"
        navigateTo="/admin/dashboard/trabalhe-conosco"
      />
    </>
  )
}

export default Dashboard
