import * as Txt from '../../styles/text'
import * as S from './styles'

import FormButtons from '../FormButtons'

const AdminContatoCard = () => {
  return (
    <>
      <S.FormContatoCard>
        <Txt.TitleMd className="text-center">
          Alterar formas de contato da escola
        </Txt.TitleMd>
        <form action="">
          <div className="mb-3">
            <div className="form-floating mb-3">
              <input
                type="tel"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                value="+8109064642904"
              />
              <label htmlFor="floatingInput">Telefone</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="tel"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                value="Teramae-33 Akashibucho, Okazaki, Aichi 444-0241"
              />
              <label htmlFor="floatingInput">Endereço</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="tel"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                value="contato@cantinhodatiachechela.com"
              />
              <label htmlFor="floatingInput">E-mail</label>
            </div>
          </div>
          <Txt.TitleMd className="text-center">
            Links de redes sociais da escola
          </Txt.TitleMd>
          <div className="form-floating mb-3">
            <input
              type="tel"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              value="https://www.facebook.com/tiachechela/?_rdr"
            />
            <label htmlFor="floatingInput">Facebook</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="tel"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              value="https://www.instagram.com/cantinhodatiachechela/"
            />
            <label htmlFor="floatingInput">Instagram</label>
          </div>
          <FormButtons leftButton="Editar" rightButton="Salvar" />
        </form>
      </S.FormContatoCard>
    </>
  )
}

export default AdminContatoCard
